@keyframes skeleton {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.skeleton {
  animation-name: skeleton;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
}
